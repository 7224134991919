.orig-featured-products__filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.orig-featured-products__filter-button {
  display: flex;
  cursor: pointer;
}

.orig-featured-products__category-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-s1);
}

.orig-featured-products__category-header-link {
  cursor: pointer;
}
