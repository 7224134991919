.orig-mobile-drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

.orig-mobile-drawer__icon {
  align-self: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: var(--spacing-s4);
}

.orig-mobile-drawer__selectable {
  cursor: pointer;
}
