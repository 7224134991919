$footer-height: 59px;

.orig-declined-page {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr $footer-height;
}

.orig-declined-page__content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-s2);
  height: 100%;
}

.orig-declined-page__message {
  margin: auto 0;
  max-width: var(--max-width-md);

  @media (max-width: $media-md) {
    margin: unset;
  }
}

.orig-declined-page__requirements {
  padding: var(--spacing-s3);
  border-radius: 4px;
  margin-bottom: var(--spacing-s3);
}

.orig-declined-page__req {
  display: flex;
}
