$footer-height: 59px;

.orig-holding-page {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr $footer-height;
}

.orig-holding-page__header {
  white-space: pre-wrap;
}
