.orig-desktop-header__navigation-wrapper {
  display: flex;
  align-items: stretch;
}

.orig-desktop-header__logo {
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.orig-desktop-header__action-wrapper {
  display: flex;
  align-items: center;
}
