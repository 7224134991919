.orig-app-status-sidebar {
  padding: var(--spacing-s5) var(--spacing-s2);
  overflow-y: auto;

  @media (max-width: $media-md) {
    display: none;
  }
}

.orig-app-status-sidebar__divider {
  width: 50px;
  height: 3px;
  margin: var(--spacing-s2) 0;
}

.orig-app-status-sidebar__row {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-s1);
}
