.orig-mobile-step-tracker {
  display: none;
  width: 100%;
  max-width: var(--max-width-md);
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: var(--spacing-s2);

  @media (max-width: $media-md) {
    display: flex;
  }
}

.orig-mobile-step-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orig-mobile-stepper {
  display: flex;
  width: 100%;
}

.orig-mobile-step-tracker__pill {
  height: 6px;
  border-radius: 1px;
  flex: 1;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}
