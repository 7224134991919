.orig-step-list-sidebar {
  padding: var(--spacing-s5) var(--spacing-s2);
  overflow-y: auto;
  height: inherit;

  @media (min-width: $media-md) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.orig-step-list-sidebar__divider {
  width: 50px;
  height: 3px;
  margin: var(--spacing-s2) 0;
}

.orig-step-list-sidebar__process-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: var(--spacing-s3);
}

.orig-step-list-sidebar__completion {
  display: flex;
  align-items: flex-end;
}

.orig-step-list-sidebar__step-name {
  display: flex;
  padding: var(--spacing-s1);
  align-items: center;
}

.orig-step-list-sidebar__step {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  &--selectable {
    @extend .orig-step-list-sidebar__step;

    cursor: pointer;
  }
}
