.uik-info-box {
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  align-items: stretch;
}

.uik-info-box__wrapper {
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0;
  padding: var(--spacing-s1) var(--spacing-s1) var(--spacing-s1) var(--spacing-s3);
  flex: 1;
}

.uik-info-box__indicator {
  width: 4px;
  height: auto;
  min-height: 100%;
  border-radius: 4px 0 0 4px;
}
