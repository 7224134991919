// Constants
$spacing: ('s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12');

// Variables
:root {
  --spacing-s1: 0.5rem;
  --spacing-s2: 1rem;
  --spacing-s3: 1.5rem;
  --spacing-s4: 2rem;
  --spacing-s5: 2.5rem;
  --spacing-s6: 3rem;
  --spacing-s7: 3.5rem;
  --spacing-s8: 4rem;
  --spacing-s9: 4.5rem;
  --spacing-s10: 5rem;
  --spacing-s11: 5.5rem;
  --spacing-s12: 6rem;
}

/*
  Trumps

  Base:
    p = padding
    m = margin

  Modifiers:
    a = all
    h = horizontal
    v = vertical
    t = top
    r = right
    b = bottom
    l = left
*/
@each $space in $spacing {
  .pa-#{$space} {
    padding: var(--spacing-#{$space});
  }

  .pl-#{$space} {
    padding-left: var(--spacing-#{$space});
  }

  .pr-#{$space} {
    padding-right: var(--spacing-#{$space});
  }

  .pb-#{$space} {
    padding-bottom: var(--spacing-#{$space});
  }

  .pt-#{$space} {
    padding-top: var(--spacing-#{$space});
  }

  .pv-#{$space} {
    padding-top: var(--spacing-#{$space});
    padding-bottom: var(--spacing-#{$space});
  }

  .ph-#{$space} {
    padding-left: var(--spacing-#{$space});
    padding-right: var(--spacing-#{$space});
  }

  .ma-#{$space} {
    margin: var(--spacing-#{$space});
  }

  .ml-#{$space} {
    margin-left: var(--spacing-#{$space});
  }

  .mr-#{$space} {
    margin-right: var(--spacing-#{$space});
  }

  .mb-#{$space} {
    margin-bottom: var(--spacing-#{$space});
  }

  .mt-#{$space} {
    margin-top: var(--spacing-#{$space});
  }

  .mv-#{$space} {
    margin-top: var(--spacing-#{$space});
    margin-bottom: var(--spacing-#{$space});
  }

  .mh-#{$space} {
    margin-left: var(--spacing-#{$space});
    margin-right: var(--spacing-#{$space});
  }
}
