.orig_faq_content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orig_faq_content_header__question {
  display: inline-block;
}

.orig_faq_content__expand {
  svg {
    transition: transform 0.1s ease-in;
    transform: rotate(0);
  }

  &--open {
    @extend .orig_faq_content__expand;

    svg {
      transform: rotate(180deg);
    }
  }
}
