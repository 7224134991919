.orig-faq-page__title {
  grid-area: title;
}

.orig-faq-page__content {
  grid-area: content;
  overflow-y: auto;
}

.faq-content-topic__section {
  margin: 0 var(--spacing-s1) var(--spacing-s2) 0;
}

.orig-faq-page__sidebar {
  grid-area: sidebar;
  @media (max-width: $media-md) {
    display: none;
  }
}

.orig-faq-page__search-mobile {
  display: none;
  @media (max-width: $media-md) {
    display: block;
  }
}
