/* stylelint-disable at-rule-no-unknown */
// Generate Grid with 1 to 12 columns
@for $i from 1 through 12 {
  .uik-grid--#{$i} {
    display: grid;
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

// Generate Columns from uik-col-(1 to 12)-(2 to 13)
@for $i from 1 through 12 {
  @for $j from 2 through 13 {
    .uik-grid-col-#{$i}-#{$j} {
      grid-column: #{$i} / #{$j};
    }
  }
}

// Generate Rows from uik-row-(1 to 12)-(2 to 13)
@for $i from 1 through 11 {
  @for $j from 2 through 12 {
    .uik-grid-row-#{$i}-#{$j} {
      grid-row: #{$i} / #{$j};
    }
  }
}

// Generate Gap from uik-gap--s(1 to 12)
@each $i in $spacing {
  .uik-gap--#{$i} {
    grid-gap: var(--spacing-#{$i})
  }
}
/* stylelint-enable */

.uik-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--spacing-s2);

  @media (max-width: $media-md) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: $media-sm) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.uik-grid__item--sm {
  grid-column: auto / span 3;
  @media (max-width: $media-md) {
    grid-column: auto / span 2;
  }
  @media (max-width: $media-sm) {
    grid-column: auto / span 2;
  }
}

.uik-grid__item--md {
  grid-column: auto / span 6;
  @media (max-width: $media-md) {
    grid-column: auto / span 4;
  }
  @media (max-width: $media-sm) {
    grid-column: auto / span 2;
  }
}

.uik-grid__item--lg {
  grid-column: auto / span 9;
  @media (max-width: $media-md) {
    grid-column: auto / span 6;
  }
  @media (max-width: $media-sm) {
    grid-column: auto / span 4;
  }
}

.uik-grid__item--xl {
  grid-column: auto / span 12;
  @media (max-width: $media-md) {
    grid-column: auto / span 8;
  }
  @media (max-width: $media-sm) {
    grid-column: auto / span 4;
  }
}
