.orig-unauthed {
  margin: auto;
  width: 100%;
  max-width: var(--unauthed-content-max-width);
  padding: var(--spacing-s2);
  @media (max-width: $media-md) {
    height: 100%;
  }
}

.orig-unauthed-ciam {
  margin: auto;
  padding: var(--spacing-s2);
  @media (max-width: $media-mobile) {
    height: 100%;
    width: 100%;
    padding: 0;
  }
}

.orig-unauthed--extend-width {
  max-width: var(--unauthed-content--extended-max-width);
}
