.orig-details-sidebar__tagline-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  padding: 0 var(--spacing-s5);

  @media (max-width: $media-md) {
    flex-direction: row;
    justify-content: center;
  }
}

.orig-details-sidebar__tagline-image {
  background-repeat: no-repeat;
  background-position: center;
  height: 70%;
  margin: var(--spacing-s2) 0;
  width: 90%;

  @media (max-width: $media-md) {
    height: 100%;
  }
}

.orig-details-sidebar__tagline-text {
  margin: var(--spacing-s3) 0;
  text-align: center;

  @media (max-width: $media-md) {
    text-align: initial;
  }
}
