.orig-quote {
  border-radius: 4px;
  padding: var(--spacing-s4);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divider {
    height: 3px;
    width: 50px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: var(--spacing-s1) var(--spacing-s2);
    width: 100%;
  }

  &__item {
    justify-self: end;
  }

  @media (max-width: $media-md) {
    padding: var(--spacing-s2);
  }
}
