$footer-height: 40px;
$header-height: 20px;

.orig-info-card-card__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.orig-info-card-card__content {
  flex-grow: 1;
}

.orig-info-card__overline_wrapper {
  display: flex;
  justify-content: space-between;
}

.orig-application-card__header {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orig-application-card__details-row {
  display: flex;
  justify-content: space-between;
}

.orig-application-card__footer {
  padding: 0 var(--spacing-s1) 0 var(--spacing-s2);
  height: $footer-height;
}
