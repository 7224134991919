$status-circle-size: 8px;

.orig-application-card-status {
  display: flex;
  align-items: center;
  height: 100%;
}

.orig-application-card-status__circle {
  height: $status-circle-size;
  width: $status-circle-size;
  border-radius: 100%;
  margin-right: var(--spacing-s1);
}
