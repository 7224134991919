.agmts-spacer-template__spacer-1 {
  height: var(--spacing-s1);
}

.agmts-spacer-template__spacer-2 {
  height: var(--spacing-s2);
}

.agmts-spacer-template__spacer-3 {
  height: var(--spacing-s3);
}
