.orig-route-menu {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  padding: var(--spacing-s4) var(--spacing-s12);
  display: flex;
  flex-direction: row;

  /*
    Above everything aside from tooltips based on MUI
    Reference: https://material-ui.com/customization/z-index/
  */
  z-index: 1499;
}

.orig-route-menu__route {
  padding-right: var(--spacing-s2);
  width: 100%;
  max-width: 240px;
}

.orig-route-menu__route-text {
  cursor: pointer;
}
