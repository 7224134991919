.orig-info-menu {
  padding-right: var(--spacing-s2);
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: $media-md) {
    padding-left: var(--spacing-s2);
    display: flex;
    flex-direction: column;
  }

  a:hover {
    color: #0053DB
  }
}