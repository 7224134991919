.orig-navigation-route {
  display: flex;
  min-width: 160px;
  align-items: center;
  cursor: pointer;
}

.orig-navigation-route__link {
  display: flex;
  align-items: center;

  svg {
    transition: transform 0.1s ease-in;
  }

  &--hover {
    @extend .orig-navigation-route__link;

    svg {
      transform: rotate(180deg);
    }
  }
}
