.uik-camera-upload__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.uik-camera-upload__button {
  align-self: flex-end;
}

.uik-camera-upload__camera-feed {
  height: 100%;
  width: 100%;
  align-self: center;
  display: block;
  border-radius: 4px;
}

.uik-camera-upload__camera-feed-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.uik-camera-upload__screenshot {
  height: inherit;
  width: auto;
}

.uik-camera-upload__camera-action-dock {
  align-self: flex-end;
}
