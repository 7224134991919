.orig-step-nav-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid;
  padding: var(--spacing-s1) var(--spacing-s4);

  @media (max-width: $media-md) {
    padding: var(--spacing-s1) var(--spacing-s2);
  }
}
