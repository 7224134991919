.uik-upload {
  margin-top: var(--spacing-s2);
}

.uik-upload_wrapper {
  display: flex;
  justify-content: center;
}

.uik-upload_zone {
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: var(--spacing-s4) 0;
  position: relative;
  cursor: pointer;

  &--uploader {
    @extend .uik-upload_zone;

    border: 1px solid;

    &:hover,
    &.active {
      border: 1px dashed;
    }
  }

  &--uploading {
    @extend .uik-upload_zone;

    cursor: auto;
    align-items: normal;
    padding: var(--spacing-s2) var(--spacing-s4);
  }
}

.uik-upload_camera_button {
  position: absolute;
  right: 13.25px;
  top: 13.25px;
}

.uik-upload_file_icon {
  padding-right: var(--spacing-s1);
}
