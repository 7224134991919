/* Inter: Regular 400 */
@font-face {
  font-family: 'Inter';
  src: url('./inter/Inter.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* Inter: Regular 500 */
@font-face {
  font-family: 'Inter';
  src: url('./inter/Inter.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* Inter: Regular 600 */
@font-face {
  font-family: 'Inter';
  src: url('./inter/Inter.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* ATB TT Norms: Regular 400 */
@font-face {
  font-family: 'ATB TT Norms';
  src: url('./atbTTNorms/ATB TT Norms Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* ATB TT Norms: Medium 700 */
@font-face {
  font-family: 'ATB TT Norms';
  src: url('./atbTTNorms/ATB TT Norms Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* ATB TT Norms: High 800 */
@font-face {
  font-family: 'ATB TT Norms';
  src: url('./atbTTNorms/ATB TT Norms ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
