.orig-loan-summary {
  border-radius: 4px;
  padding: var(--spacing-s2);
  display: grid;
  grid-template-rows: auto 1fr auto;

  &--editing {
    @extend .orig-loan-summary;

    opacity: 0.3;
    pointer-events: none;
  }
}

.orig-loan-summary__table {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-gap: var(--spacing-s1);
  margin: auto 0;
}

.orig-loan-summary__table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orig-loan-summary__disclaimer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--spacing-s1);
  margin-top: var(--spacing-s2);
}
