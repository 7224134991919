.uik-count-indicator {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--small {
    @extend .uik-count-indicator;

    min-width: var(--small-icon-size);
    min-height: var(--small-icon-size);
  }

  &--default {
    @extend .uik-count-indicator;

    min-width: var(--default-icon-size);
    min-height: var(--default-icon-size);
  }

  &--large {
    @extend .uik-count-indicator;

    min-width: var(--large-icon-size);
    min-height: var(--large-icon-size);
  }
}
