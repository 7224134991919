$icon-size: 36px;

.orig-requirement-section__wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-s3);
  align-items: center;
}

.orig-requirement-section__icon-wrapper {
  margin-right: var(--spacing-s4);
  min-height: $icon-size;
  min-width: $icon-size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
