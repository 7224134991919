@import '../../styles/index';

.uik-drawer {
  width: 100vw;
  height: 100%;
  padding: var(--spacing-s2);
  display: flex;
  flex-direction: column;
  transition: width 0.2s;
}
