.orig-return-home-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid;
  padding: var(--spacing-s1) var(--spacing-s4);

  @media (max-width: $media-md) {
    padding: var(--spacing-s1) var(--spacing-s2);
  }
}
