.orig-details-sidebar__catalog-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 var(--spacing-s5);
  width: auto;
}

.orig-details-sidebar__catalog-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--spacing-s1);
  height: 150px;
  margin: var(--spacing-s2) 0;
  width: 255px;
}

.orig-details-sidebar__catalog-image-vertical {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--spacing-s1);
  height: 250px;
  margin: var(--spacing-s2) 0;
  width: 156px;
}
