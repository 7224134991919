$header-height-desktop: 64px;
$header-height-mobile: 56px;
$banner-height: 54px;
$sidebar-height-mobile: 352px;
$content-height-desktop: calc(100% - $header-height-desktop);
$faq-title-height: 212px;
$large-main-card-container: 1176px;
$medium-main-card-container: 568px;

.uik-layout__header {
  grid-area: header;
}

.uik-layout__sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  @media (max-width: $media-md) {
    display: none;
  }
}

.uik-layout__sidebar-display {
  grid-area: sidebar;
  overflow-y: auto;
  @media (max-width: $media-md) {
    display: none;
  }
}

.uik-layout__title {
  grid-area: title;
}

.orig-featured-application-pages__header {
  display: flex;
  justify-content: space-between;
  background-color: #edf5fe;
  padding: var(--spacing-s7) 0 var(--spacing-s7) var(--spacing-s10);
  align-items: center;
  margin-bottom: var(--spacing-s6);

  @media (max-width: $media-md) {
    padding: var(--spacing-s4) var(--spacing-s2);
  }

  .orig-featured-application-pages__headerText {
    margin: 0 auto;
    width: calc($large-main-card-container - var(--spacing-s10));

    @media (max-width: $media-md) {
      width: $medium-main-card-container;
      padding-left: var(--spacing-s2);
    }

    @media (max-width: $media-sm) {
      width: 100%;
      padding-left: 0;
      margin: 0;
    }
  }

  .orig-featured-application-pages__h2 {
    font-size: 36px;
    line-height: 44px;

    @media (max-width: $media-md) {
      font-size: 28px;
      line-height: 32px;
    }
  }
}

.orig-featured-application-pages__content {
  padding: 0 var(--spacing-s10);
  max-width: $large-main-card-container;
  margin: 0 auto;

  @media (max-width: $media-md) {
    max-width: $medium-main-card-container;
    padding: 0 var(--spacing-s2);
  }

  @media (max-width: $media-sm) {
    max-width: none;
    margin: 0;

    .orig-featured-application-pages__content-container {
      width: 100%;
    }
  }

  .orig-featured-application-pages__h3 {
    font-size: 30px;
    line-height: 36px;

    @media (max-width: $media-md) {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

@mixin uik-layout($selector, $header-height, $header-height-mobile) {
  .uik-layout__content#{$selector},
  .uik-layout__content-ciam#{$selector} {
    grid-area: content;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .uik-layout__content#{$selector} {
    @media (max-width: $media-md) {
      overflow-y: unset;
    }
  }

  .uik-layout--master#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'header header' $header-height
      'sidebar content' calc(100% - $header-height)
      / 350px 1fr;

    @media (max-width: $media-md) {
      grid-template:
        'header header' $header-height
        'content content' calc(100% - $header-height)
        / 350px 1fr;
    }
  }

  .uik-layout--master-no-sidebar#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'header header' $header-height
      'content content' calc(100% - $header-height)
      / 350px 1fr;
  }

  .uik-layout--landing#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'header header' $header-height
      'content sidebar' calc(100% - $header-height)
      / 1.5fr 2fr;

    @media (max-width: $media-md) {
      grid-template:
        'header header' $header-height
        'content content' calc(100% - $header-height)
        / 1.5fr 2fr;
    }
  }

  .uik-layout--display#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'header  header' $header-height
      'sidebar content' calc(100% - $header-height)
      / 1fr 2fr;

    @media (max-width: $media-xl) {
      grid-template:
        'header  header' $header-height
        'sidebar content' calc(100% - $header-height)
        / 360px 1fr;
    }

    @media (max-width: $media-md) {
      grid-template:
        'header  header' $header-height
        'content content' calc(100% - $header-height)
        / 1fr 2fr;
    }
  }

  .uik-layout--display-no-sidebar#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'header  header' $header-height
      'content content' calc(100% - $header-height)
      / 1fr 2fr;
  }

  .uik-layout--ciam#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      'content content' 100%
      / 1fr 2fr;
  }

  .uik-layout--ciam-no-sidebar,
  .uik-layout--ciam-no-sidebar--banner {
    height: 100%;
  }

  .uik-layout--faq#{$selector} {
    display: grid;
    height: 100%;
    grid-template:
      '. header  header .' $header-height
      '. title title .' $faq-title-height
      '. sidebar content .' calc(100% - $header-height - $faq-title-height)
      / 1fr 1fr 3fr 1fr;

    @media (max-width: $media-md) {
      padding: 0 var(--spacing-s2);
      grid-template:
        'header' $header-height-mobile
        'title' min-content
        'content' 1fr
        / 1fr;
    }
  }
}

@include uik-layout('', $header-height-desktop, $header-height-mobile);
@include uik-layout(
  '--banner',
  calc($header-height-desktop + $banner-height),
  calc($header-height-mobile + $banner-height)
);
