.orig-product-details-sidebar {
  @media (max-width: $media-md) {
    height: var(--desktop-banner-photo-height);
    background-position: top;
  }
}

.orig-product-details-sidebar__loader {
  margin-top: var(--desktop-header-height);
}
