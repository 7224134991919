$media-xs: 0;
$media-sm: 599px;
$media-md: 959px;
$media-lg: 1279px;
$media-xl: 1919px;
$media-mobile: 430px;

:root {
  --media-xs: $media-xs;
  --media-sm: $media-sm;
  --media-md: $media-md;
  --media-lg: $media-lg;
  --media-xl: $media-xl;
  --media-mobile: $media-mobile;
}
