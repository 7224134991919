.uik-table {
  display: flex;
  flex-direction: column;
}

.uik-row--flex {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uik-table__row {
  @extend .uik-row--flex;

  margin: 0 0 var(--spacing-s1) 0;
}

.uik-table__header {
  @extend .uik-row--flex;

  margin-top: var(--spacing-s2);
}

.uik-table__group-header {
  padding: var(--spacing-s1) 0 0 0;
}

.uik-summary__bottom-divider {
  margin: var(--spacing-s2) 0 var(--spacing-s2) 0;
}
