.orig-loan-request {
  padding: var(--spacing-s2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orig-loan-request__editing-limit {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: var(--spacing-s1);
  margin-bottom: var(--spacing-s1);
}

.orig-loan-request__edit-limit {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
