.forms-autocomplete-field {
  /*
  Note: This is not ideal but there is currently no way to modify lab components using the default theme objects
        Once `Autocomplete` is released into `@material-ui/core` then this can be removed so it can be overridden
        correctly
  */
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
    padding-right: 38px;
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }
}
