.orig-applications__content {
  width: 100%;
  height: 100%;
}

.orig-applications__no-applications {
  height: calc(100% - var(--desktop-header-height));
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orig-applications__application-container {
  display: flex;
}
