.orig-loan-payment {
  border-radius: 4px;
  padding: var(--spacing-s2);

  &--editing {
    @extend .orig-loan-payment;

    opacity: 0.3;
    pointer-events: none;
  }
}

.orig-loan-payment__payment-bounds {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--spacing-s1);
}
