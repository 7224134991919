.orig-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.orig-mobile-header__route-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
