.orig-faq-title {
  padding: var(--spacing-s5) 0 0 0;
  justify-content: space-around;
  @media (max-width: $media-md) {
    margin: 0;
  }
}

.orig-faq-title__search {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-s5);
}

.orig-faq-title__caption {
  padding: var(--spacing-s1) 0 var(--spacing-s3) 0;
}

.orig-faq-title__ui_divider {
  margin: var(--spacing-s2) 0 var(--spacing-s4) 0;
}
