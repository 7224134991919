.uik-skeleton-template {
  width: 100%;
}

.uik-skeleton-template__header {
  width: 50%;
}

.uik-skeleton-template__subheader {
  width: 35%;
}

.uik-skeleton-template__paragraph-wrapper {
  margin-top: var(--spacing-s2);
}

.uik-skeleton-template__paragraph {
  &:last-child {
    width: 80%;
    margin-bottom: 0;
  }
}
