.orig-workflow {
  width: 100%;
  height: 100%;
  display: flex;

  form {
    height: 100%;
    width: 100%;
  }

  &--holding {
    @extend .orig-workflow;

    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    @media (max-width: $media-md) {
      justify-content: start;
    }
  }

  &--loading {
    @extend .orig-workflow--holding;

    padding: var(--spacing-s2);
  }
}

.orig-workflow__loader {
  margin: auto 0;
  width: 100%;
  height: 100%;
  max-width: var(--max-width-md);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $media-md) {
    margin: unset;
  }
}
