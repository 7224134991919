$footer-height: 59px;

.orig-step-builder {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr $footer-height;
}

.orig-step-builder__content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-s2);
  height: 100%;
}

.orig-step-builder__margin {
  height: var(--spacing-s3);
}

.orig-step-builder__step {
  margin: auto 0;
  padding-top: var(--spacing-s8);
  width: 100%;

  @media (max-width: $media-md) {
    padding: unset;
    margin: unset;
  }

  &--md {
    @extend .orig-step-builder__step;

    max-width: var(--max-width-md);
  }

  &--sm {
    @extend .orig-step-builder__step;

    max-width: var(--max-width-sm);
  }

  &--lg {
    @extend .orig-step-builder__step;

    max-width: var(--max-width-lg);
  }
}

.orig-step-builder__header {
  margin: auto 0;
  width: 100%;

  @media (max-width: $media-md) {
    display: none;
  }
}
