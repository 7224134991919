.orig-products-summary__content {
  width: 100%;
}

.orig-products__banner {
  height: var(--desktop-banner-photo-height);
  border-radius: 4px;
  background-size: cover;
  margin: var(--spacing-s2) var(--spacing-s2) var(--spacing-s4) var(--spacing-s2);

  @media (max-width: $media-md) {
    height: var(--mobile-banner-photo-height);
    border-radius: 0;
    margin: 0;
  }
}
